* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans",
  sans-serif;
  font-size: 20px;
  color: #444;
  text-align: center;
  line-height: 1.5;
  min-width: 320px;
}

nav,
footer {
  padding: 1% 0;
  background-color: #aaa;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.nav-right {
  text-align: right;
}

.nav-links {
  padding: 10px;
  margin:5px;
}

header {
  padding: 45px 15px;
}

section {
  min-height: 200px;
  padding: 30px 15px;
  background-color: #bbb;
}

section:nth-child(even) {
  background-color: #ddd;
}

p {
  margin: 0 auto;
  max-width: 650px;
}

p + p {
  margin-top: 15px;
}

form {
  text-align: left;
}

input, textarea {
  width: 100%;
  max-width: 500px;
}

input[type="number"] {
  width: 50px;
}

label {
  display: block;
}

.form-section {
  display:block;
  margin-bottom: 30px;
}

.favor-type-explanation {
  font-size: 14px;
}

.favor-entry {
  min-height: 0;
}

.favor-entry header {
  padding: 0;
}

.favor-entry header h3 {
  margin: 0;
}

.favor-description {
  text-align: left;
  margin-bottom: 30px;
}

.favor-stat {
  color: blue;
}
