nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #aaa;
  display:flex;
  justify-content: space-between;
}

.nav-right {
  text-align: right;
  padding:10px;
  margin-left:40px;
}
.nav-left {
  text-align: left;
  padding:10px;
  margin-left:40px;
}

.nav-links {
  padding: 10px;
  margin:5px;
}

header {
  padding: 2% 0;
}
