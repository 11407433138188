* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans",
  sans-serif;
  font-size: 20px;
  color: #444;
  text-align: center;
  line-height: 1.5;
}

section {
  min-height: 200px;
  padding: 6% 0;
  background-color: #bbb;
}

section:nth-child(even) {
  background-color: #ddd;
}
